<template>
  <a-modal
    title="上传打款凭证"
    cancelText="取消"
    okText="确定"
    :visible="visible"
    width="550px"
    @ok="handleUpdateStatus"
    @cancel="handleCancel"
    class="payment-voucher-dialog"
  >
    <div class="clearfix">
      <a-upload
        name="avatar"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        :customRequest="upload"
        accept=".png, .jpg"
      >
        <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 435px;height: 200px" />
        <div
          v-else
          class="bg"
          style="width: 435px;height: 200px; background: #555555; line-height: 200px;color: #40a9ff;"
        >上传打款凭证（png/jpg）</div>
      </a-upload>
    </div>
    <div class="tips" style="color:red; margin-top: 15px;" >温馨提示：因线下打款存在到账时限问题，平台会尽快完成收款及发货，可在上传打卡凭证后，2-3个工作日在待收货订单查看物流信息。</div>
  </a-modal>
</template>

<script>
import { uploadImage, employmentOrderUpdate } from '@/api/materialShop'
export default {
  name: 'PaymentVoucher',
  data () {
    return {
      visible: false,
      imageUrl: '',
      orderId: ''
    }
  },
  methods: {
    $init (orderId) {
      this.imageUrl = ''
      this.orderId = orderId
      this.visible = true
    },
    handleUpdateStatus () {
      if (!this.imageUrl) {
        this.$message.error('请上传打款凭证')
      } else {
        const params = {
          status: 8,
          id: this.orderId,
          paymentVoucher: this.imageUrl
        }
        employmentOrderUpdate(params).then(res => {
          if (res.success) {
            this.visible = false
            this.$message.success('操作成功')
            this.$emit('success')
          }
        })
      }
    },
    handleCancel () {
      this.visible = false
      this.imageUrl = ''
    },
    upload (file) {
      const form = new FormData()
      form.append('file', file.file)
      this.$spin.show()
      uploadImage(form)
        .then(res => {
          this.imageUrl = res.path
        })
        .finally(() => {
          this.$spin.hide()
        })
    }
  }
}
</script>

<style lang="less">
.payment-voucher-dialog {
  .clearfix {
    width: 100%;
    padding: 0 25px;
    .bg {
      background: #555555;
      width: 420px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: blue;
    }
  }
  .border-box {
    border: 1px solid #c4c4c4;
    padding: 15px 20px;
  }
  .page-title {
    font-weight: 550;
    font-size: 18px;
    margin-bottom: 18px;
  }
  .transfer-info {
    display: flex;
    align-items: baseline;
    font-weight: 400;
    .label-content {
      width: 120px;
      text-align: right;
      font-weight: 500;
      .info-label {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .value-content {
      flex: 1;
      .info-value {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .copy-btn {
      color: #3d77ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
